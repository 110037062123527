import {
  Action,
  configureStore,
  ConfigureStoreOptions,
  ThunkAction,
} from "@reduxjs/toolkit";

import caseSlice from "./case/slice";
import modalSlice from "./modal/slice";
import userSlice from "./user/slice";
import { api } from "./api";
import { rtkQueryErrorLogger } from "./error-logger";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      caseSlice,
      userSlice,
      modalSlice,
      [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(rtkQueryErrorLogger).concat(api.middleware),
    ...options,
  });

export const store = createStore({
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
