import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { customHistory } from "../../helpers/CustomerBrowserRouter";
import { ICase } from "../../types/Case";
import { caseApi } from "../case/api";

const initialState: {
  currentCase: ICase | null;
  caseImage: null | { name: string; image: string };
  caseLoading: boolean;
  caseImageLoading: boolean;
} = {
  caseImage: null,
  currentCase: null,
  caseLoading: false,
  caseImageLoading: false,
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    clearCaseImage: (state) => {
      state.caseImageLoading = false;
      state.caseImage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        caseApi.endpoints.postCaseCreate.matchFulfilled,
        (state, action) => {
          // customHistory.push(`/app/cases/${action.payload.id}`);
        }
      )

      .addMatcher(caseApi.endpoints.putCaseUpdate.matchPending, (state) => {
        state.caseLoading = true;
      })
      .addMatcher(caseApi.endpoints.putCaseUpdate.matchFulfilled, (state) => {
        state.caseLoading = false;
      })
      .addMatcher(caseApi.endpoints.putCaseUpdate.matchFulfilled, (state) => {
        state.caseLoading = false;
      })

      .addMatcher(caseApi.endpoints.getCase.matchPending, (state) => {
        state.caseLoading = true;
      })
      .addMatcher(caseApi.endpoints.getCase.matchFulfilled, (state, action) => {
        state.currentCase = action.payload;
        state.caseLoading = false;
      })
      .addMatcher(caseApi.endpoints.getCase.matchFulfilled, (state) => {
        state.caseLoading = false;
      })

      .addMatcher(
        caseApi.endpoints.getCasesPredictionAI.matchPending,
        (state) => {
          state.caseLoading = true;
        }
      )
      .addMatcher(
        caseApi.endpoints.getCasesPredictionAI.matchFulfilled,
        (state, action) => {
          state.currentCase = action.payload;
          state.caseLoading = false;
        }
      )
      .addMatcher(
        caseApi.endpoints.getCasesPredictionAI.matchFulfilled,
        (state) => {
          state.caseLoading = false;
        }
      )

      .addMatcher(caseApi.endpoints.postCasesImage.matchPending, (state) => {
        state.caseImageLoading = true;
      })
      .addMatcher(
        caseApi.endpoints.postCasesImage.matchFulfilled,
        (state, action) => {
          state.caseImageLoading = false;
        }
      )
      .addMatcher(caseApi.endpoints.postCasesImage.matchFulfilled, (state) => {
        state.caseImageLoading = false;
      })
      .addMatcher(caseApi.endpoints.getCasesImage.matchPending, (state) => {
        state.caseImageLoading = true;
      })
      .addMatcher(
        caseApi.endpoints.getCasesImage.matchFulfilled,
        (state, action) => {
          state.caseImage = action.payload;
          state.caseImageLoading = false;
        }
      )
      .addMatcher(caseApi.endpoints.getCasesImage.matchFulfilled, (state) => {
        state.caseImageLoading = false;
      });
  },
});

export const { clearCaseImage } = caseSlice.actions;
export default caseSlice.reducer;
