export const COOKIES = {
  TOKEN: "token",
};

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const API_TAGS = {
  USER: "user",
  CASE: "case",
  TRG: "trg",
};

export const VALIDATION_ERRORS = {
  NOT_VALID_FIELD: "Please enter a valid value",
  NOT_VALID_PASSWORD:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
  REQUIRED_FIELD: "Required field",
  SPACES: "The field must not contain extra spaces",
  PASSWORD_NOT_MATCHES: "Password doesn't match",
  SUCH_ACCOUNT_WITH_FIELD_EXISTS: (fieldName: string) =>
    `User with "${fieldName}" already exists!`,
};

export const MESSAGES = {
  ERROR_HAPPENED: "An error has occurred.",
  USER_SUCCESS_REGISTERED: "User successfully registered",
};

export const REGULAR_EXPRESSIONS = {
  // Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  // Cyrillic forbidden
  WITHOUT_CYRILLIC: /^(?!.*[А-ЯЁа-яё])/,
  // Kazakhstan number
  KZ_PHONE_NUMBER: /^\+?77(\d{9})$/,
};

export const DATE_FORMAT = "dd.MM.yyyy";

export enum LoadingStatus {
  LOADING = "loading",
  LOADED = "loaded",
  FAILED = "failed",
}
