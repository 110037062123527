import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useDeleteCaseMutation } from "../../app/case/api";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeDeletePatientConfirmationModal } from "../../app/modal/slice";
import Icon from "../../icons";

import { DeletePatientConfirmationWrapper } from "./styles";

const PhoneConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const [deleteCase, { isLoading }] = useDeleteCaseMutation();
  const { isOpen, caseId } = useAppSelector(
    (state) => state.modalSlice.deletePatientConfirmation
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDelete = () => {
    if (caseId && typeof caseId === "number") {
      deleteCase(caseId);
    }
  };

  const handleCloseModal = () => {
    dispatch(
      changeDeletePatientConfirmationModal({ isOpen: false, caseId: null })
    );
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      fullWidth
    >
      <DeletePatientConfirmationWrapper>
        <button
          className="close-button"
          type="button"
          onClick={handleCloseModal}
        >
          <Icon icon="close" />
        </button>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Typography
              sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: 500 }}
              variant="body2"
            >
              Вы уверенны что хотите удалить кейс?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingButton
              disabled={isLoading}
              sx={{ marginRight: "28px", maxWidth: 146 }}
              variant="app-btn-gray-sm"
              onClick={handleCloseModal}
            >
              Отменить
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              sx={{ maxWidth: 146 }}
              variant="app-btn-gray-sm"
              onClick={handleDelete}
            >
              Да
            </LoadingButton>
          </Box>
        </DialogContent>
      </DeletePatientConfirmationWrapper>
    </Dialog>
  );
};

export default PhoneConfirmationModal;
