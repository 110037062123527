import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changeNewPatientModal } from "../../app/modal/slice";
import AppSelect from "../../components/AppSelect";
import Input from "../../components/Input";
import { GENDER_OPTIONS } from "../../helpers/options";
import Icon from "../../icons";

import { NewPatientWrapper } from "./styles";
import { useNewPatientForm } from "./useNewPatientForm";

const PhoneConfirmationModal = () => {
  const { formik, isLoading } = useNewPatientForm();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.modalSlice.newPatient);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDelete = () => {};

  const handleCloseModal = () => {
    dispatch(changeNewPatientModal({ isOpen: false }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      fullWidth
    >
      <NewPatientWrapper>
        <button
          className="close-button"
          type="button"
          onClick={handleCloseModal}
        >
          <Icon icon="close" />
        </button>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid justifyContent="center" spacing={2} container>
              <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
                <Typography variant="h3">Add a new case</Typography>
              </Grid>
              <Grid xs={12} item>
                <Input
                  error={
                    formik.touched.patientLastName &&
                    !!formik.errors.patientLastName
                  }
                  helperText={
                    (formik.touched.patientLastName &&
                      formik.errors.patientLastName) ||
                    ""
                  }
                  label="Last Name"
                  name="patientLastName"
                  value={formik.values.patientLastName}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  error={
                    formik.touched.patientFirstName &&
                    !!formik.errors.patientFirstName
                  }
                  helperText={
                    (formik.touched.patientFirstName &&
                      formik.errors.patientFirstName) ||
                    ""
                  }
                  label="Name"
                  name="patientFirstName"
                  type="text"
                  value={formik.values.patientFirstName}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={6} item>
                <AppSelect
                  error={
                    formik.touched.patientGender &&
                    !!formik.errors.patientGender
                  }
                  helperText={
                    (formik.touched.patientGender &&
                      formik.errors.patientGender) ||
                    ""
                  }
                  label="Gender"
                  name="patientGender"
                  options={GENDER_OPTIONS}
                  type="text"
                  value={formik.values.patientGender}
                  variant="filled"
                  onChange={(v) => formik.setFieldValue("patientGender", v)}
                />
              </Grid>
              <Grid xs={6} item>
                <Input
                  error={
                    formik.touched.patientAge && !!formik.errors.patientAge
                  }
                  helperText={
                    (formik.touched.patientAge && formik.errors.patientAge) ||
                    ""
                  }
                  label="Age"
                  name="patientAge"
                  type="number"
                  value={formik.values.patientAge}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <Input
                  error={
                    formik.touched.doctorFullName &&
                    !!formik.errors.doctorFullName
                  }
                  helperText={
                    (formik.touched.doctorFullName &&
                      formik.errors.doctorFullName) ||
                    ""
                  }
                  label="Doctor"
                  name="doctorFullName"
                  type="text"
                  value={formik.values.doctorFullName}
                  variant="filled"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid xs={12} item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingButton
                    loading={isLoading}
                    sx={{ maxWidth: 295, fontSize: "16px", fontWeight: 500 }}
                    type="submit"
                    variant="app-button-sm"
                    onClick={handleDelete}
                  >
                    Create
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </NewPatientWrapper>
    </Dialog>
  );
};

export default PhoneConfirmationModal;
