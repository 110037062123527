import React from "react";
import { Container } from "@mui/material";

import { AppFooterWrapper } from "./styles";

const AppFooter = () => {
  return (
    <AppFooterWrapper>
      <Container></Container>
    </AppFooterWrapper>
  );
};

export default AppFooter;
