import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Konva from "konva";

import { store } from "./app/store";
import Fallback from "./components/Fallback";
import { CustomBrowserRouter } from "./helpers/CustomerBrowserRouter";
import App from "./App";
import Modals from "./modals";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

const container = document.getElementById("root")!;
const root = createRoot(container);
Konva.dragButtons = [2];

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CustomBrowserRouter>
          <React.Suspense fallback={<Fallback />}>
            <App />
            <Modals />
          </React.Suspense>
          <ToastContainer theme="dark" />
        </CustomBrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
