import styled from "@emotion/styled";

export const SignUpWrapper = styled.div`
  box-sizing: border-box;
  padding: 124px 0 20px;
  display: flex;
  form {
    max-width: 500px;
  }
  .info {
    position: relative;
    padding-top: 12rem;
    padding-left: 4rem;
    p {
      position: relative;
      z-index: 2;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      max-width: 382px;
    }
    ul {
      position: relative;
      z-index: 2;
      max-width: 327px;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        &:before {
          margin-right: 10px;
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background: var(--blue-color);
        }
      }
    }
  }
  .info-img {
    position: absolute;
    top: -10px;
    left: 330px;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 510px;
    width: 525px;
    height: 582px;
  }
  @media screen and (max-width: 1200px) {
    .info {
      display: none;
    }
  }
`;
