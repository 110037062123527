export default {
  HOME: "/",
  MAIN: "/main",
  CEPHALOMETRIC_ANALYSIS: "/cephalometric-analysis",
  APP_SIGNIN: "/app/auth",
  APP_SIGNUP: "/app/auth/sign-up",
  APP_FORGOT_PASSWORD: "/app/auth/forgot-password",
  APP_CASES: "/app/cases",
  CASE: "/app/cases/:id/:type/:status",
  generate(path: string, ...rest: string[]): string {
    let pathsArray = path.split("/");
    let index = 0;

    pathsArray = pathsArray.map((item) => {
      if (item.indexOf(":") > -1) {
        const url = rest[index];
        index += 1;
        return url;
      }
      return item;
    });

    return pathsArray.join("/");
  },
};
