import React from "react";

import "./styles.scss";

export interface IGraph {
  title?: string;
  icon: string;
  name: string;
  children?: IGraph[];
  color?: "yellow";
}
interface IItemProps {
  item: IGraph;
  num?: number;
  numOf?: number;
}
const MainGraphItem: React.FC<IItemProps> = ({ item, num, numOf }) => {
  return (
    <div
      className={
        "mGraph__item" +
        (num !== undefined && numOf !== undefined
          ? " ch" + (num + 1) + "_of_" + numOf
          : "")
      }
    >
      <div className={"mGraph__content " + item.color}>
        {item.title && <h4 className="mGraph__title">{item.title}</h4>}
        <div className="mGraph__icon">
          <svg className="text-black-100">
            <use
              xlinkHref={
                process.env.PUBLIC_URL + "icons/sprite.svg#" + item.icon
              }
            ></use>
          </svg>
        </div>
        <h5 className="mGraph__name">{item.name}</h5>
      </div>
      {item.children && (
        <div className="mGraph__row">
          {item.children.map((child, index) => {
            return (
              <MainGraphItem
                item={child}
                key={child.icon}
                num={index}
                numOf={item.children?.length}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
export default MainGraphItem;
