import { API_TAGS } from "../../helpers/constants";
import {
  IPostChangePasswordRequestProps,
  IPostConfirmationCodeRequestProps,
  IPostForgotPasswordRequestProps,
  IPostLoginRequestProps,
  IPostLoginResponseProps,
  IPostSignUpRequestProps,
  IPostSignUpResponseProps,
  IUser,
} from "../../types/User";
import { api } from "../api";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<
      IPostLoginResponseProps,
      IPostLoginRequestProps
    >({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.USER, API_TAGS.CASE],
    }),
    postSignUp: builder.mutation<
      IPostSignUpResponseProps,
      IPostSignUpRequestProps
    >({
      query: (credentials) => ({
        url: "sign-up",
        method: "POST",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.USER],
    }),
    postCheckSignUp: builder.mutation<
      null,
      Omit<IPostSignUpRequestProps, "confirmationCode">
    >({
      query: (credentials) => ({
        url: "check-sign-up",
        method: "POST",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.USER],
    }),
    postForgotPassword: builder.mutation<null, IPostForgotPasswordRequestProps>(
      {
        query: (credentials) => ({
          url: "forgot-password",
          method: "POST",
          body: credentials,
        }),
        extraOptions: {},
        invalidatesTags: [API_TAGS.USER],
      }
    ),
    postChangePassword: builder.mutation<null, IPostChangePasswordRequestProps>(
      {
        query: (credentials) => ({
          url: "change-password",
          method: "POST",
          body: credentials,
        }),
        extraOptions: {},
        invalidatesTags: [API_TAGS.USER],
      }
    ),
    postConfirmationCode: builder.mutation<
      null,
      IPostConfirmationCodeRequestProps
    >({
      query: (credentials) => ({
        url: "confirmation-code",
        method: "POST",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.USER],
    }),
    getCurrentUser: builder.query<IUser, null>({
      query: () => ({ url: "current-user" }),
      providesTags: () => [API_TAGS.USER],
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostCheckSignUpMutation,
  usePostSignUpMutation,
  usePostConfirmationCodeMutation,
  usePostForgotPasswordMutation,
  usePostChangePasswordMutation,
  useGetCurrentUserQuery,
} = userApi;
