import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { usePostLoginMutation } from "../../app/user/api";
import { toggleIsRememberMe } from "../../app/user/slice";
import { VALIDATION_ERRORS } from "../../helpers/constants";
import yup from "../../helpers/yup";

export const useSignInForm = () => {
  const dispatch = useAppDispatch();
  const isRememberMe = useAppSelector((state) => state.userSlice.isRememberMe);
  const [postLogin, { isLoading }] = usePostLoginMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(VALIDATION_ERRORS.NOT_VALID_FIELD)
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        .trim(),
      password: yup
        .string()
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        // .matches(
        //   REGULAR_EXPRESSIONS.PASSWORD,
        //   VALIDATION_ERRORS.NOT_VALID_PASSWORD
        // )
        .trim(),
    }),
    onSubmit: async (values) => {
      postLogin({ email: values.email, password: values.password });
    },
  });

  const handleRememberMe = () => dispatch(toggleIsRememberMe());

  return {
    formik,
    handleRememberMe,
    isRememberMe,
    isLoading,
  };
};
