import styled from "@emotion/styled";

export const PhoneInputWrapper = styled.div`
  box-sizing: border-box;
  .form-control {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    &::placeholder {
      font-size: 24px;
      color: #8f95a1;
      color: #fff;
    }
    &:focus {
      border: none;
    }
  }
  .react-tel-input {
    max-width: 300px;
    background: #253041;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    outline: none;
  }
  .country-list {
    background: #253041;
    .country {
      &.highlight,
      &:hover {
        background: #ffffff30;
      }
    }
  }
  .flag-dropdown {
    background: #253041;
    border: none;
    &.open {
      .selected-flag {
        background: #253041;
      }
    }
    .selected-flag {
      &:focus {
        background: #253041;
      }
      &:hover {
        background: #253041;
      }
    }
  }
  @media screen and (max-width: 600px) {
  }
`;
