import React from "react";
import { HiEnvelope } from "react-icons/hi2";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Typography } from "@mui/material";

import AppCheckbox from "../../components/AppCheckbox";
import Input from "../../components/Input";
import PATH from "../../helpers/PATH";
import Icon from "../../icons";

import { SignUpWrapper } from "./styles";
import { useSignUpForm } from "./useSignUpForm";

const SignUp = () => {
  const { formik, isAgree, handleIsAgree } = useSignUpForm();

  return (
    <SignUpWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Grid spacing={2} container>
          <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
            <Typography variant="h3">Create an account</Typography>
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.email && !!formik.errors.email}
              helperText={(formik.touched.email && formik.errors.email) || ""}
              icon={<HiEnvelope />}
              label="Email"
              name="email"
              tooltipText="Enter your mail. example: zebraeye@gmail.com"
              type="email"
              value={formik.values.email}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.lastName && !!formik.errors.lastName}
              helperText={
                (formik.touched.lastName && formik.errors.lastName) || ""
              }
              icon={<Icon icon="user-contacts" />}
              label="Last name"
              name="lastName"
              tooltipText="Enter last name"
              type="text"
              value={formik.values.lastName}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.firstName && !!formik.errors.firstName}
              helperText={
                (formik.touched.firstName && formik.errors.firstName) || ""
              }
              icon={<Icon icon="user-contacts" />}
              label="Name"
              name="firstName"
              tooltipText="Enter name"
              type="text"
              value={formik.values.firstName}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.clinicName && !!formik.errors.clinicName}
              helperText={
                (formik.touched.clinicName && formik.errors.clinicName) || ""
              }
              icon={<Icon icon="user-contacts" />}
              label="Clinic name"
              name="clinicName"
              tooltipText="Enter clinic name"
              type="text"
              value={formik.values.clinicName}
              variant="filled"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={formik.touched.password && !!formik.errors.password}
              helperText={
                (formik.touched.password && formik.errors.password) || ""
              }
              icon={<HiEnvelope />}
              label="Enter password"
              name="password"
              // tooltipText="Пароль должен содержать латинские буквы разного регистра, цифры, а так же специальные символы (@, #, $ и тд…)"
              tooltipText="Enter password"
              type="password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <Input
              error={
                formik.touched.matchingPassword &&
                !!formik.errors.matchingPassword
              }
              helperText={
                (formik.touched.matchingPassword &&
                  formik.errors.matchingPassword) ||
                ""
              }
              icon={<HiEnvelope />}
              label="Repeat password"
              name="matchingPassword"
              tooltipText="Repeat password"
              type="password"
              value={formik.values.matchingPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            alignItems="center"
            justifyContent="space-between"
            paddingBottom={2}
            xs={12}
            container
            item
          >
            <AppCheckbox
              checked={isAgree}
              label={
                <span className="text">
                  I accept the terms of the &nbsp;
                  <span className="text text--blue text--semi-bold text--inline">
                    User Agreement
                  </span>
                  &nbsp; и &nbsp;
                  <span className="text text--blue text--semi-bold text--inline">
                    Privacy Policy.
                  </span>
                </span>
              }
              onChange={handleIsAgree}
            />
          </Grid>
          <Grid md={11} xs={12} item>
            <LoadingButton type="submit" variant="app-button">
              Sign Up
            </LoadingButton>
          </Grid>
          <Grid alignItems="flex-start" md={11} xs={12} item>
            <Box sx={{ textAlign: "center" }}>
              <p className="text">
                <span>Already have an account? </span>
                <Link
                  className="text text--bold text--primary"
                  to={PATH.APP_SIGNIN}
                >
                  Login
                </Link>
              </p>
            </Box>
          </Grid>
        </Grid>
      </form>
    </SignUpWrapper>
  );
};

export default SignUp;
