export const CASE_FILTER_OPTIONS = [
  {
    value: "FAVORITE",
    label: "Избранное",
  },
  {
    value: "ARCHIVED",
    label: "Архив",
  },
];

export const GENDER_OPTIONS = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
];
