import DeletePatientConfirmation from "./DeletePatientConfirmation";
import NewPatient from "./NewPatient";
import PhoneConfirmation from "./PhoneConfirmation";

const Modals = () => {
  return (
    <>
      <PhoneConfirmation />
      <DeletePatientConfirmation />
      <NewPatient />
    </>
  );
};

export default Modals;
