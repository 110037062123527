import React from "react";
import ReactOtpInput, { OtpInputProps } from "react18-input-otp";

import { OtpInputWrapper } from "./styles.module";

interface IProps extends OtpInputProps {
  className?: string;
}

const OtpInput: React.FC<IProps> = ({ className, ...otherProps }) => {
  return (
    <OtpInputWrapper>
      <ReactOtpInput
        containerStyle={className}
        focusStyle={{ boxShadow: "0 0 12px -2px var(--accent-color)" }}
        inputStyle={{ width: 32, transition: "all 0.3s ease" }}
        separator={<div style={{ width: 32 }} />}
        {...otherProps}
      />
    </OtpInputWrapper>
  );
};

export default OtpInput;
