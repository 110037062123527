import React from "react";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

import { useAppSelector } from "../../../app/hooks";
import UserMenu from "../../../components/UserMenu";
import PATH from "../../../helpers/PATH";

import { AppHeaderWrapper } from "./styles";
const AppHeader = () => {
  const token = useAppSelector((state) => state.userSlice.token);

  return (
    <AppHeaderWrapper>
      <Container>
        <div className="header">
          <Link className="logo" to={token ? PATH.APP_CASES : PATH.APP_SIGNIN}>
            zebraeye
          </Link>
          <menu>
            <li>
              <UserMenu mode="light" />
            </li>
          </menu>
        </div>
      </Container>
    </AppHeaderWrapper>
  );
};

export default AppHeader;
