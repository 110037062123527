import styled from "@emotion/styled";

export const AppCheckboxWrapper = styled.label`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    padding: 0;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #253041;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin-right: 11px;
  }
  svg {
    transition: all 0.3s ease;
    font-size: 20px;
    color: #253041;
  }
  input:checked ~ div {
    background: #4cbfff;
    svg {
      color: #fff;
    }
  }
  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    user-select: none;
  }
`;
