import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  usePostChangePasswordMutation,
  usePostForgotPasswordMutation,
} from "../../app/user/api";
import { toggleIsRememberMe } from "../../app/user/slice";
import { VALIDATION_ERRORS } from "../../helpers/constants";
import yup from "../../helpers/yup";

export const useForgotPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const isChangePasswordLoading = useAppSelector(
    (state) => state.userSlice.isChangePasswordLoading
  );
  const [forgotPassword, { isSuccess: isForgotPasswordSuccess }] =
    usePostForgotPasswordMutation();
  const [changePassword, { isSuccess: isChangePasswordSuccess }] =
    usePostChangePasswordMutation();

  useEffect(() => {
    if (isForgotPasswordSuccess) {
      setStep(2);
    }
  }, [isForgotPasswordSuccess]);

  useEffect(() => {
    if (isChangePasswordSuccess) {
      setStep(3);
    }
  }, [isChangePasswordSuccess]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      matchingPassword: "",
      confirmationCode: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(VALIDATION_ERRORS.NOT_VALID_FIELD)
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        .trim(),
      password: yup
        .string()
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        // .matches(
        //   REGULAR_EXPRESSIONS.PASSWORD,
        //   VALIDATION_ERRORS.NOT_VALID_PASSWORD
        // )
        .trim(),
      matchingPassword: yup
        .string()
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        .oneOf(
          [yup.ref("password"), null],
          VALIDATION_ERRORS.PASSWORD_NOT_MATCHES
        ),
      confirmationCode: yup
        .string()
        .min(6, VALIDATION_ERRORS.NOT_VALID_FIELD)
        .max(6, VALIDATION_ERRORS.NOT_VALID_FIELD)
        .required(VALIDATION_ERRORS.REQUIRED_FIELD),
    }),
    onSubmit: async (values) => {
      changePassword(values);
    },
  });
  const handleRememberMe = () => dispatch(toggleIsRememberMe());

  const confirmEmail = useCallback(() => {
    formik.setFieldTouched("email", true, true).then((errors) => {
      if (!errors?.email) {
        forgotPassword({ email: formik.values.email });
      }
    });
  }, [formik]);

  const handleRoute = () => {
    navigate(-1);
  };

  return {
    handleRememberMe,
    confirmEmail,
    handleRoute,
    formik,
    step,
    isChangePasswordLoading,
  };
};
