import React from "react";

const typography = {
  fontSize: 16,
  fontFamily: ["Montserrat", "Roboto", "-apple-system", "sans-serif"].join(","),
  bold1: {
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "39px",
    color: "#C2D4F0",
    "@media (max-width:600px)": {
      fontSize: "24px",
      lineHeight: "25px",
    },
  },
  h3: {
    fontWeight: "700",
    fontSize: "23px",
    color: "#fff",
    "@media (max-width:600px)": {
      fontSize: "18px",
    },
  },
  h4: {
    fontWeight: "500",
    fontSize: "26px",
    color: "#fff",
    "@media (max-width:600px)": {
      fontSize: "21px",
    },
  },
  h5: {
    fontWeight: "600",
    fontSize: "22px",
    color: "#fff",
  },
  small1: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#8F95A1",
  },
  small12: {
    fontWeight: "400",
    fontSize: "12px",
    color: "#8F95A1",
  },
  small2: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#fff",
  },
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bold1: React.CSSProperties;
    small1: React.CSSProperties;
    small2: React.CSSProperties;
    small12: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bold1?: React.CSSProperties;
    small1?: React.CSSProperties;
    small2?: React.CSSProperties;
    small12?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bold1: true;
    small1: true;
    small2: true;
    small12: true;
  }
}

export default typography;
