import React from "react";
import { IoPersonSharp } from "react-icons/io5";
import { Box, Button, Fade, Menu, MenuItem, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { signOut } from "../../app/user/slice";
import Tooltip from "../Tooltip";

import { UserMenuWrapper } from "./styles";

interface IProps {
  mode?: "dark" | "light";
}

const UserMenu: React.FC<IProps> = ({ mode = "dark" }) => {
  const user = useAppSelector((state) => state.userSlice.user);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    handleClose();
  };

  if (!user) return null;

  return (
    <UserMenuWrapper mode={mode}>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Настройки аккаунта">
          <Button
            aria-controls={open ? "account-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            size="small"
            sx={{ ml: 2 }}
            onClick={handleClick}
          >
            <IoPersonSharp
              color="#7C859A"
              fontSize={24}
              style={{ marginRight: 6 }}
            />
            <Typography fontSize={14}>{user?.firstName || ""}</Typography>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "rgba(20, 29, 44, 1)",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: mode === "dark" ? "grey.200" : "#253041",
            },
            backgroundColor: mode === "light" ? "rgba(20, 29, 44, 1)" : "#fff",
            color: mode === "light" ? "#fff" : "#000",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        TransitionComponent={Fade}
        onClose={handleClose}
      >
        {/*<MenuItem onClick={handleClose}>Мой профиль</MenuItem>*/}
        <MenuItem onClick={handleSignOut}>Exit</MenuItem>
      </Menu>
    </UserMenuWrapper>
  );
};

// "&:before": {
//   content: "",
//   display: "block",
//   position: "absolute",
//   top: 0,
//   right: 14,
//   width: 10,
//   height: 10,
//   left: "50%",
//   bgcolor: "background.paper",
//   transform: "translateY(-50%) rotate(45deg)",
//   zIndex: 0,
// },

export default UserMenu;
