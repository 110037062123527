import { useState } from "react";

import MainGraphItem, { IGraph } from "../../components/MainGraphItem";

import "./styles.css";

const Main = () => {
  const graph: IGraph[] = [
    {
      icon: "cataracts",
      name: "The patient has indications for cataract surgery",
      children: [
        {
          icon: "optometry",
          name: "Diagnostics and endothelial microscopy",
          children: [
            {
              title: "Standard treatment process",
              icon: "optometrist",
              name: "Microscopy is not obvious and the doctor may miss the pathology",
              children: [
                {
                  icon: "vision",
                  name: "Wrong surgical strategy chosen",
                  children: [
                    {
                      icon: "eye-pain",
                      name: "Minimized risk of High risk of vision loss loss",
                    },
                  ],
                },
              ],
            },
            {
              title: "Treatment process with ZebraEye",
              icon: "eye-floaters",
              name: "Scan is analyzed by AI",
              color: "yellow",
              children: [
                {
                  icon: "eye-check",
                  name: "Proper surgical strategy",
                  color: "yellow",
                  children: [
                    {
                      icon: "optician",
                      name: "Minimized risk of vision loss",
                      color: "yellow",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const openForm = function () {
    const element = document.querySelector(
      '[data-b24-crm-button-widget="crmform"]'
    );
    if (element) {
      const clickEvent = new Event("click", {
        bubbles: true,
        cancelable: true,
      });
      element.dispatchEvent(clickEvent);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const togglePageScrolling = () => {
    document.body.style.overflow = isOpen ? "auto" : "hidden";
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    togglePageScrolling();
  };

  const scrollToElementById = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      toggleMenu();
    }
  };
  return (
    <>
      <header className="bg-black-100">
        <div className="container flex items-center h-20 gap-x-4 justify-between relative">
          <img
            alt="Logo"
            height={30}
            src={process.env.PUBLIC_URL + "images/logo.svg"}
            width={144}
          />
          <ul className="md:flex ml-auto items-center gap-x-6 hidden">
            <li>
              <a className="text-white text-sm font-medium" href="#innovation">
                Innovation
              </a>
            </li>
            <li>
              <a
                className="text-white text-sm font-medium"
                href="#how-does-it-work"
              >
                How does it work?
              </a>
            </li>
          </ul>
          <ul
            className={
              "md:hidden absolute w-full h-screen top-20 left-0 z-10 bg-black-100 flex flex-col gap-y-6 container py-6" +
              " " +
              (isOpen ? "flex" : "hidden")
            }
          >
            <li>
              <button
                className="text-white text-lg font-medium w-full text-center"
                onClick={() => scrollToElementById("innovation")}
              >
                Innovation
              </button>
            </li>
            <li>
              <button
                className="text-white text-lg font-medium w-full text-center"
                onClick={() => scrollToElementById("how-does-it-work")}
              >
                How does it work?
              </button>
            </li>
            <li>
              <button className="mBtn w-full text-center" onClick={openForm}>
                Try for free
              </button>
            </li>
          </ul>
          <button className="md:block mBtn hidden" onClick={openForm}>
            Try for free
          </button>
          <button
            className="md:hidden h-10 w-10 flex items-center justify-center"
            onClick={toggleMenu}
          >
            <svg className="w-6 h-6 text-white">
              <use
                xlinkHref={
                  process.env.PUBLIC_URL +
                  "icons/sprite.svg#" +
                  (isOpen ? "close" : "menu")
                }
              ></use>
            </svg>
          </button>
        </div>
      </header>
      <section
        className="bg-cover bg-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}images/main-hero.png)`,
        }}
      >
        <div className="container lg:py-56 md:py-40 py-28 flex items-center">
          <article>
            <h1 className="lg:w-[37.5rem] text-white lg:text-6xl font-bold md:text-5xl text-4xl">
              Better detection
              <br />
              of rare eye diseases
            </h1>
            <p className="lg:w-[31rem] text-white lg:text-2xl font-normal mt-4 text-xl">
              By combining the power of artificial
              <br />
              intelligence and doctors.
            </p>
            <button className="mt-6 mBtn" onClick={openForm}>
              Experience ZebraEye
            </button>
          </article>
        </div>
      </section>
      <section
        className="bg-cover bg-bottom bg-black-100"
        id="innovation"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}images/main-zebra.png)`,
        }}
      >
        <div className="container pt-32 pb-20 flex flex-col items-center justify-center text-center">
          <h2 className="text-white font-bold lg:text-[5.25rem] md:text-4xl text-3xl lg:leading-none max-w-5xl mx-auto shadow-black drop-shadow-md mix-blend-difference">
            Innovation that makes a difference
          </h2>
          <p className="text-white text-center lg:text-3xl text-2xl max-w-lg mx-auto mt-10">
            There are over{" "}
            <mark className="text-yellow-100 bg-transparent">550</mark> rare eye
            <br />
            They affect{" "}
            <mark className="text-yellow-100 bg-transparent">
              480 million
            </mark>{" "}
            <br />
            More than{" "}
            <mark className="text-yellow-100 bg-transparent">40%</mark> are
            misdiagnosed.
          </p>
          <h4 className="text-center text-white font-bold lg:text-4xl text-3xl mt-12">
            ZebraEye aims to change that
          </h4>
        </div>
      </section>
      <section className="bg-black-100 md:py-32 py-20" id="how-does-it-work">
        <div className="container">
          <h2 className="text-white font-bold text-5xl">How ?</h2>
          <div className="lg:flex-row flex flex-col items-center justify-between mt-10">
            <img
              alt=""
              className="lg:w-[25.5rem] lg:h-[25.5rem]"
              src={process.env.PUBLIC_URL + "images/main-how.png"}
            />
            <div className="max-w-lg">
              <h3 className="text-yellow-100 text-medium text-4xl">
                AI identifies a rare eye pathology within 1 minute
              </h3>
              <p className="text-white text-2xl mt-6">
                Reducing the risk of improper treatment leading to vision loss.
              </p>
            </div>
          </div>

          <h4 className="text-white text-2xl">
            Example: Fuchs Endothelial Corneal Dystrophy (FECD)
          </h4>
          <div className="flex flex-col items-center mt-10">
            {graph &&
              graph.map((item) => (
                <MainGraphItem item={item} key={item.icon} />
              ))}
          </div>

          <div className="text-center md:mt-28 mt-16">
            <img
              alt="macOS"
              className="mx-auto"
              src={process.env.PUBLIC_URL + "images/main-mac.png"}
            />
            <h3 className="text-white text-2xl mt-5 mb-6">
              ZebraEye is an ophthalmic image analysis system that helps <br />
              physicians with rare pathologies.
            </h3>
            <span className="text-yellow-100 cursor-pointer" onClick={openForm}>
              Try it in web version
            </span>
          </div>

          <h2 className="text-white font-bold text-4xl text-center mt-28">
            Benefits for doctors
          </h2>
          <div className="md:flex-row flex flex-col items-center justify-center mt-10">
            <div className="px-5 rounded-full border flex flex-col items-center justify-center md:w-96 md:h-96 border-black-70 w-56 h-56">
              <svg className="lg:w-32 lg:h-32 w-24 h-24 text-yellow-100">
                <use
                  xlinkHref={
                    process.env.PUBLIC_URL + "icons/sprite.svg#eye-check"
                  }
                ></use>
              </svg>
              <span className="text-white text-center block lg:text-lg text-base">
                No rare pathologies are missed
              </span>
            </div>
            <div className="px-5 rounded-full border flex flex-col items-center justify-center md:w-96 md:h-96 border-black-70 w-56 h-56">
              <svg className="lg:w-32 lg:h-32 w-24 h-24 text-yellow-100">
                <use
                  xlinkHref={
                    process.env.PUBLIC_URL + "icons/sprite.svg#optometrist"
                  }
                ></use>
              </svg>
              <span className="text-white text-center block lg:text-lg text-base">
                Confidence in decison-making
              </span>
            </div>
            <div className="px-5 rounded-full border flex flex-col items-center justify-center md:w-96 md:h-96 border-black-70 w-56 h-56">
              <svg className="lg:w-32 lg:h-32 w-24 h-24 text-yellow-100">
                <use
                  xlinkHref={process.env.PUBLIC_URL + "icons/sprite.svg#eye"}
                ></use>
              </svg>
              <span className="text-white text-center block lg:text-lg text-base">
                99.6% less negative consequences due to misdiagnosis
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer className="bg-white py-10">
        <div className="container flex items-center justify-between">
          <div className="flex flex-col gap-y-10">
            <img
              alt="ZebraEye"
              src={process.env.PUBLIC_URL + "images/logo-black.svg"}
            />
            <div>
              <h4 className="text-lg text-black-100 font-medium">Contacts</h4>
              <p className="text-sm text-black-100 font-normal mt-2">
                E-mail: info@zebraeye.ai <br />
                Address: <br />
                Kazakhstan, Almaty <br />
                Dostyk Avenue 210A <br />
                KOKTEM GRAND 5th floor, office 53
              </p>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <ul className="flex gap-x-6 mt-6">
              <li>
                <a
                  href="https://www.linkedin.com/company/zebraeye/mycompany/?viewAsMember=true"
                  rel="noopener"
                  target="_blank"
                >
                  <svg className="w-5 h-5">
                    <use
                      xlinkHref={
                        process.env.PUBLIC_URL + "icons/sprite.svg#linkedin"
                      }
                    ></use>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/zebraeye.ai/"
                  rel="noopener"
                  target="_blank"
                >
                  <svg className="w-5 h-5">
                    <use
                      xlinkHref={
                        process.env.PUBLIC_URL + "icons/sprite.svg#instagram"
                      }
                    ></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Main;
