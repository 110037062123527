import React from "react";
import { HiEnvelope } from "react-icons/hi2";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, Typography } from "@mui/material";

import Input from "../../components/Input";

import { ForgotPasswordWrapper } from "./styles";
import { useForgotPasswordForm } from "./useForgotPasswordForm";

const ForgotPassword = () => {
  const { formik, step, confirmEmail, isChangePasswordLoading, handleRoute } =
    useForgotPasswordForm();

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Grid spacing={2} container>
            <Grid sx={{ marginBottom: 2, marginLeft: 1 }} xs={12} item>
              <Typography variant="h3">Forgot password?</Typography>
            </Grid>
            <Grid xs={11} item>
              <Typography
                sx={{ paddingRight: 1.5, paddingLeft: 1.5 }}
                variant="body2"
              >
                Enter your e-mail and we will send you instructions for
                receiving new password.
              </Typography>
            </Grid>
            <Grid xs={11} item>
              <Input
                error={formik.touched.email && !!formik.errors.email}
                helperText={(formik.touched.email && formik.errors.email) || ""}
                icon={<HiEnvelope />}
                label="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid md={11} xs={12} item>
              <LoadingButton
                loading={isChangePasswordLoading}
                type="button"
                variant="app-button"
                onClick={confirmEmail}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid spacing={2} container>
            <Grid sx={{ marginLeft: 1 }} xs={12} item>
              <Typography variant="h4">
                The letter has been sent <br /> to your email
              </Typography>
            </Grid>
            <Grid xs={11} item>
              <Typography
                sx={{ paddingRight: 1.5, paddingLeft: 1.5 }}
                variant="body2"
              >
                We have sent an email with a confirmation code to change your
                password &nbsp; <b>{formik.values.email}</b> &nbsp; (make sure
                it's not a folder spam folder).
              </Typography>
            </Grid>
            <Grid xs={11} item>
              <Input
                error={
                  formik.touched.confirmationCode &&
                  !!formik.errors.confirmationCode
                }
                helperText={
                  (formik.touched.confirmationCode &&
                    formik.errors.confirmationCode) ||
                  ""
                }
                icon={<HiEnvelope />}
                label="Enter code"
                name="confirmationCode"
                value={formik.values.confirmationCode}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={11} item>
              <Input
                error={formik.touched.password && !!formik.errors.password}
                helperText={
                  (formik.touched.password && formik.errors.password) || ""
                }
                icon={<HiEnvelope />}
                label="Enter password"
                name="password"
                type="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={11} item>
              <Input
                error={
                  formik.touched.matchingPassword &&
                  !!formik.errors.matchingPassword
                }
                helperText={
                  (formik.touched.matchingPassword &&
                    formik.errors.matchingPassword) ||
                  ""
                }
                icon={<HiEnvelope />}
                label="Repeat password"
                name="matchingPassword"
                type="password"
                value={formik.values.matchingPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid md={11} xs={12} item>
              <LoadingButton
                loading={isChangePasswordLoading}
                type="submit"
                variant="app-button"
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Box
            sx={{
              minHeight: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Box sx={{ maxWidth: "500px" }}>
              <Grid justifyContent="center" spacing={1} container>
                <Grid xs={12} item>
                  <Typography variant="h3">
                    Password has changed successful
                  </Typography>
                </Grid>
                <Grid marginBottom={3} xs={12} item>
                  <Typography sx={{ color: "#8F95A1" }} variant="body2">
                    You need to log in with an updated password.
                  </Typography>
                </Grid>
                <Grid md={11} xs={12} item>
                  <Button
                    type="button"
                    variant="app-button"
                    onClick={handleRoute}
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <ForgotPasswordWrapper step={step}>
      <form onSubmit={formik.handleSubmit}>
        {renderStep()}
        <Grid
          alignItems="center"
          justifyContent="space-between"
          md={11}
          xs={12}
          container
          item
        >
          <Grid xs="auto" item></Grid>
          {/*<Grid xs="auto" item>*/}
          {/*  <Link className="text text--blue text--bold" to="..">*/}
          {/*    Отмена*/}
          {/*  </Link>*/}
          {/*</Grid>*/}
        </Grid>
      </form>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
