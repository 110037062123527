export interface ITrgAnalysisType {
  id: 1;
  name: string;
}

export type TCaseType = "CBCT" | "TRG";
export type TCaseFilterType = "ARCHIVE" | "FAVORITE";
export type TCaseTRGStatus = "NEW" | "CALIBRATION" | "DIGITIZATION" | "READY";
export type TCaseCBCTStatus = "NEW" | "READY" | "IMAGE_UPLOADED";

export interface IGetCasesResponseProps {
  content: ICase[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface IGetCasesRequestProps {
  q?: string;
  caseTypes?: TCaseType[];
  filters?: string[];
  page: number;
  limit?: number;
}

export interface ICase {
  label: null | string;
  score: null | string;
  filters: [];
  id: number;
  imagePath: string | null;
  patientLastName?: string | null;
  patientFirstName?: string | null;
  patientFullName: string | null;
  patientGender: string | null;
  patientAge: string | null;
  doctorFullName: string | null;
  caseType: TCaseType;
  caseStatus: TCaseCBCTStatus;
  caseTRGStatus: TCaseTRGStatus;
  caseCBCTStatus: TCaseCBCTStatus;
  trgPixelLengthInMm: null;
  trgAnalysisType: ITrgAnalysisType;
  createdAt: string | null;
}

export interface IPostCaseCreateRequestProps {
  caseType: string;
  patientLastName: string;
  patientFirstName: string;
  patientGender: string;
  patientAge: string;
  doctorFullName: string;
}

export enum ECaseType {
  CBCT = "CBCT",
  TRG = "TRG",
}

export enum ECaseTRGStatus {
  NEW = "NEW",
  IMAGE_UPLOADED = "IMAGE_UPLOADED",
  CALIBRATION = "CALIBRATION",
  DIGITIZATION = "DIGITIZATION",
  READY = "READY",
}

export enum ECaseCBCTStatus {
  NOT_UPLOADED = "NOT_UPLOADED",
  UPLOADED = "UPLOADED",
  READY = "READY",
}
