import { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { togglePhoneConfirmationModal } from "../../app/modal/slice";
import { fillInSignUpForm } from "../../app/user/slice";
import {
  // REGULAR_EXPRESSIONS,
  VALIDATION_ERRORS,
} from "../../helpers/constants";
import yup from "../../helpers/yup";

export const useSignUpForm = () => {
  const dispatch = useAppDispatch();
  const signUpForm = useAppSelector((state) => state.userSlice.signUpForm);
  const [isAgree, setIsAgree] = useState(false);

  const formik = useFormik({
    initialValues: signUpForm,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email(VALIDATION_ERRORS.NOT_VALID_FIELD)
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        .trim(),
      // confirmationCode: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      lastName: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      firstName: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      clinicName: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      password: yup
        .string()
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        // .matches(
        //   REGULAR_EXPRESSIONS.PASSWORD,
        //   VALIDATION_ERRORS.NOT_VALID_PASSWORD
        // )
        .trim(),
      matchingPassword: yup
        .string()
        .required(VALIDATION_ERRORS.REQUIRED_FIELD)
        .oneOf(
          [yup.ref("password"), null],
          VALIDATION_ERRORS.PASSWORD_NOT_MATCHES
        ),
    }),
    onSubmit: async (values) => {
      if (!isAgree) {
        return toast.warn("You have not confirmed the user agreements!");
      }
      dispatch(fillInSignUpForm(values));
      dispatch(togglePhoneConfirmationModal(true));
    },
  });

  return {
    formik,
    isAgree,
    handleIsAgree: () => setIsAgree(!isAgree),
  };
};
