import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import PATH from "../../helpers/PATH";

import { AuthLayoutWrapper } from "./styles";

const AuthLayout = () => {
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.userSlice.token);

  useEffect(() => {
    if (token) navigate(PATH.APP_CASES);
  }, [token]);

  return (
    <AuthLayoutWrapper>
      <Container>
        <Box>
          <Outlet />
        </Box>
      </Container>
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
