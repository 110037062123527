import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import queryString from "query-string";

import { API_TAGS } from "../helpers/constants";

import { RootState } from "./store";

const baseUrl = process.env.REACT_APP_SERVER_ENDPOINT + "/api/";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl,
  paramsSerializer: (parsed) => {
    const stringified = queryString.stringify(parsed, { arrayFormat: "comma" });
    return stringified;
  },
  mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).userSlice.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types.ts,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "splitApi",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types.ts must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [API_TAGS.USER, API_TAGS.CASE, API_TAGS.TRG],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => "test",
  }),
});
