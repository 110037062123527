// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
export function checkIIN(
  iin: string | undefined,
  __offset?: number /* Внутренний параметр. Игнорируйте его */
): boolean {
  if (!iin) return false;

  const IIN_LENGTH = 12; // Длина ИИНа

  if (typeof iin !== "string" || iin.length !== IIN_LENGTH) {
    return false;
  }

  __offset = __offset ? Number(__offset) : 0;

  let checkSum = 0;

  for (let i = 0; i < IIN_LENGTH - 1; ++i) {
    const weight = 1 + ((__offset + i) % IIN_LENGTH);
    checkSum += Number(iin.charAt(i)) * weight;
  }

  checkSum %= IIN_LENGTH - 1;

  if (checkSum === 10 && __offset === 0) {
    return checkIIN(iin, 2);
  }

  return Number(iin.charAt(IIN_LENGTH - 1)) === checkSum;
}

export function handleFormikArrayField<ArrayType>(
  field: string,
  array: ArrayType[],
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  emptyFieldValue?: ArrayType,
  operation = true,
  index?: number
) {
  const arrayFields = _.cloneDeep(array);

  switch (operation) {
    case true:
      setFieldValue(field, _.concat(arrayFields, [emptyFieldValue]));
      break;
    case false:
      setFieldValue(
        field,
        _.filter(arrayFields, (v, i) => i !== index)
      );
      break;
  }
}

export const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

export const convertBase64ToBlob = (base64Image: string) => {
  // Split into two parts
  const parts = base64Image.split(";base64,");

  // Hold the content type
  const imageType = parts[0].split(":")[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
};

export const createFormData = (field: string, file: File | Blob) => {
  const formData = new FormData();
  formData.append(field, file);
  return formData;
};

export const putNumber: (value: any, cb: (v: number) => void) => void = (
  value,
  cb
) => {
  const v = _.toNumber(value);
  if (_.isFinite(v)) cb(value);
};

const declension = ["years", "years", "years"];
export const pluralAge = (age: number, titles: string[] = declension) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    age % 100 > 4 && age % 100 < 20 ? 2 : cases[age % 10 < 5 ? age % 10 : 5]
  ];
};
