import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { togglePhoneConfirmationModal } from "../../app/modal/slice";
import {
  usePostCheckSignUpMutation,
  usePostConfirmationCodeMutation,
  usePostSignUpMutation,
} from "../../app/user/api";
import { clearSignUpForm, fillInSignUpForm } from "../../app/user/slice";
import OtpInput from "../../components/OtpInput";
import PhoneInput from "../../components/PhoneInput";
import PATH from "../../helpers/PATH";
import Icon from "../../icons";

import { PhoneConfirmationWrapper } from "./styles";

const PhoneConfirmationModal = () => {
  const [checkSignUp, { isSuccess: isCheckSignUpSuccess }] =
    usePostCheckSignUpMutation();
  const [confirmCode, { isSuccess: isConfirmationCodeSuccess }] =
    usePostConfirmationCodeMutation();
  const [postSignUp, { isSuccess: isSignUpSuccess }] = usePostSignUpMutation();
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector(
    (state) => state.modalSlice.phoneConfirmation
  );
  const { signUpForm, isSignUpLoading } = useAppSelector(
    (state) => state.userSlice
  );
  const navigate = useNavigate();
  const THREE_MINUTES_LATER = 3 * 60 * 1000;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dateTimer, setDateTimer] = useState(0);
  const [isTimedOut, setIsTimedOut] = useState(true);
  const [isTypingConfirmationCode, setIsTypingConfirmationCode] =
    useState(false);
  const [code, setCode] = useState("");

  const handleStartDateTimer = () => {
    setDateTimer(Date.now() + THREE_MINUTES_LATER);
    setIsTimedOut(false);
    setIsTypingConfirmationCode(true);
  };

  const handleComplete = () => {
    setIsTimedOut(true);
    setIsTypingConfirmationCode(false);
    setCode("");
  };

  const handleSubmit = () => {
    if (!isTypingConfirmationCode && isTimedOut && !code.length) {
      return checkSignUp(signUpForm);
    }
    if (isTypingConfirmationCode && !isTimedOut && code.length === 6) {
      return postSignUp({ ...signUpForm, confirmationCode: code });
    }
  };

  const handleCloseModal = () => {
    if (isSignUpLoading || isTypingConfirmationCode) return null;
    dispatch(togglePhoneConfirmationModal(false));
  };

  useEffect(() => {
    if (isCheckSignUpSuccess) {
      confirmCode({ phoneNumber: signUpForm.phoneNumber });
    }
  }, [isCheckSignUpSuccess]);

  useEffect(() => {
    if (isConfirmationCodeSuccess) {
      handleStartDateTimer();
    }
  }, [isConfirmationCodeSuccess]);

  useEffect(() => {
    if (isSignUpSuccess) {
      navigate(PATH.APP_SIGNIN);
      dispatch(clearSignUpForm());
      dispatch(togglePhoneConfirmationModal(false));
    }
  }, [isSignUpSuccess]);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      open={isOpen}
      PaperProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
      fullWidth
    >
      <PhoneConfirmationWrapper>
        <button
          className="close-button"
          type="button"
          onClick={handleCloseModal}
        >
          <Icon icon="close" />
        </button>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <PhoneInput
              disabled={
                isSignUpLoading || !isTimedOut || isTypingConfirmationCode
              }
              placeholder="Phone number"
              value={signUpForm.phoneNumber}
              onChange={(value) =>
                dispatch(fillInSignUpForm({ phoneNumber: value }))
              }
            />
            {/*<Button className="change-number-btn" variant="text">*/}
            {/*  Изменить номер*/}
            {/*</Button>*/}
          </Box>
          {!isTimedOut && (
            <Box className="sms">
              <p>
                We have sent an SMS with a confirmation code to your number
                <br />
                phone. Please enter this code below
              </p>
              <span>SMS confirmation code</span>
              <Box className="otp-box">
                <OtpInput
                  className="otp"
                  numInputs={6}
                  value={code}
                  onChange={setCode}
                  onSubmit={(v: string) => console.log(v)}
                />
                <p>
                  Code is valid &nbsp;
                  <Countdown
                    date={dateTimer}
                    renderer={({ formatted: { minutes, seconds } }) => (
                      <span>
                        {minutes}:{seconds}
                      </span>
                    )}
                    onComplete={handleComplete}
                  />
                </p>
              </Box>
            </Box>
          )}
          <LoadingButton
            loading={isSignUpLoading}
            sx={{ maxWidth: 214 }}
            variant="app-button"
            onClick={handleSubmit}
          >
            {isTypingConfirmationCode ? "Confirm" : "Continue"}
          </LoadingButton>
        </DialogContent>
      </PhoneConfirmationWrapper>
    </Dialog>
  );
};

export default PhoneConfirmationModal;
