import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { usePostCaseCreateMutation } from "../../app/case/api";
import { useAppDispatch } from "../../app/hooks";
import { changeNewPatientModal } from "../../app/modal/slice";
import { VALIDATION_ERRORS } from "../../helpers/constants";
import PATH from "../../helpers/PATH";
import yup from "../../helpers/yup";
import { ECaseCBCTStatus, ECaseTRGStatus, ECaseType } from "../../types/Case";

export const useNewPatientForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createCase, { isLoading, isSuccess, data }] =
    usePostCaseCreateMutation();

  const formik = useFormik({
    initialValues: {
      caseType: "TRG",
      patientLastName: "",
      patientFirstName: "",
      patientGender: "",
      patientAge: "",
      doctorFullName: "",
    },
    validationSchema: yup.object().shape({
      caseType: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      patientLastName: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      patientFirstName: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      patientGender: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      patientAge: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
      doctorFullName: yup.string().required(VALIDATION_ERRORS.REQUIRED_FIELD),
    }),
    onSubmit: async (values, { resetForm }) => {
      createCase(values);
    },
  });

  useEffect(() => {
    if (isSuccess && data && data.id) {
      const caseStatus =
        formik.values.caseType === ECaseType.TRG
          ? ECaseTRGStatus.NEW
          : ECaseCBCTStatus.NOT_UPLOADED;
      navigate(
        PATH.generate(
          PATH.CASE,
          data.id.toString(),
          formik.values.caseType,
          caseStatus
        )
      );
      formik.resetForm();
      dispatch(changeNewPatientModal({ isOpen: false }));
    }
  }, [isSuccess, data]);

  return {
    formik,
    isLoading,
  };
};
