import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "./app/hooks";
import { useGetCurrentUserQuery } from "./app/user/api";
import EmptyBox from "./components/EmptyBox";
import Fallback from "./components/Fallback";
import PATH from "./helpers/PATH";
import AppLayout from "./layouts/App";
import AppBodyLayout from "./layouts/AppBody";
import AuthLayout from "./layouts/Auth";
import ForgotPassword from "./views/ForgotPassword";
import Main from "./views/Main";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";

import "./App.scss";

const Cases = lazy(() => import("./views/Cases"));
const Case = lazy(() => import("./views/Case"));

const AppAuthRoutes = () => {
  return (
    <Route element={<AppBodyLayout />}>
      <Route element={<Main />} path="main" />
      <Route path="cases">
        <Route element={<Cases />} index />
        <Route element={<Case />} path=":id/:type/:status" />
        <Route element={<Case />} path=":id" />
        <Route element={<EmptyBox />} path="*" />
      </Route>
    </Route>
  );
};

function App() {
  const token = useAppSelector((state) => state.userSlice.token);
  useGetCurrentUserQuery(null, { skip: !token });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      if (location.pathname !== PATH.MAIN && location.pathname !== PATH.HOME) {
        navigate(PATH.APP_SIGNIN);
      } else {
        navigate(PATH.MAIN);
      }
    } else {
      navigate(PATH.APP_CASES);
    }
  }, [token]);

  return (
    <Suspense fallback={<Fallback />}>
      <Routes>
        <Route element={<Main />} path="/main" />
        <Route element={<AppLayout />} path="/app">
          <Route element={<AuthLayout />} path="auth">
            <Route element={<SignIn />} index />
            <Route element={<SignUp />} path="sign-up" />
            <Route element={<ForgotPassword />} path="forgot-password" />
          </Route>
          {AppAuthRoutes()}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
