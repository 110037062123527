import { API_TAGS } from "../../helpers/constants";
import {
  ICase,
  IGetCasesRequestProps,
  IGetCasesResponseProps,
  IPostCaseCreateRequestProps,
} from "../../types/Case";
import { api } from "../api";

export const caseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCases: builder.query<IGetCasesResponseProps, IGetCasesRequestProps>({
      query: (params) => ({ url: "cases/all", params }),
      providesTags: () => [API_TAGS.CASE],
    }),
    getCase: builder.query<ICase, string | undefined>({
      query: (id: string) => ({ url: `cases/${id}` }),
      providesTags: () => [API_TAGS.CASE],
    }),
    getCasesPredictionAI: builder.query<ICase, string>({
      query: (id: string) => ({ url: `cases/prediction/ai/${id}` }),
      providesTags: () => [API_TAGS.CASE],
    }),
    postCaseCreate: builder.mutation<
      { id: number },
      IPostCaseCreateRequestProps
    >({
      query: (credentials) => ({
        url: "cases",
        method: "POST",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.CASE],
    }),
    putCaseUpdate: builder.mutation<
      unknown,
      { id: string } & Omit<IPostCaseCreateRequestProps, "caseType">
    >({
      query: ({ id, ...credentials }) => ({
        url: `cases/${id}`,
        method: "PUT",
        body: credentials,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.CASE],
    }),
    deleteCase: builder.mutation<null, number>({
      query: (id) => ({
        url: `cases/${id}`,
        method: "DELETE",
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.CASE],
    }),
    postCasesImage: builder.mutation<
      unknown,
      { caseId: number | string; file: FormData }
    >({
      query: ({ caseId, file }) => ({
        url: `cases/image/${caseId}`,
        method: "POST",
        body: file,
      }),
      extraOptions: {},
      invalidatesTags: [API_TAGS.TRG, API_TAGS.CASE],
    }),
    getCasesImage: builder.query<
      { name: string; image: string },
      string | number
    >({
      query: (id) => ({ url: `cases/image/${id}` }),
      transformResponse: async (response: {
        name: string;
        image: string;
      }): Promise<{ name: string; image: string }> => {
        // const splittedName = response.name.split(".");
        return {
          ...response,
          image: `data:image;base64,${response.image}`,
        };
      },
      providesTags: () => [API_TAGS.CASE],
    }),
  }),
});

export const {
  useGetCasesQuery,
  useGetCaseQuery,
  usePostCaseCreateMutation,
  usePutCaseUpdateMutation,
  useDeleteCaseMutation,
  usePostCasesImageMutation,
  useGetCasesImageQuery,
  useGetCasesPredictionAIQuery,
} = caseApi;
